import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps } from "vue";
import { onMounted } from 'vue';
import VForm from 'web/src/components/Form/components/VForm/VForm.vue';
import VStepDescription from 'web/src/components/StepDescription/VStepDescription/VStepDescription.vue';
import EnableStepDownload from 'web/src/modules/two-factor-authentication/components/EnableStepDownload/EnableStepDownload.vue';
import EnableStepSetup from 'web/src/modules/two-factor-authentication/components/EnableStepSetup/EnableStepSetup.vue';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import SwiperNavigationPoints from 'web/src/components/Swiper/Navigation/SwiperNavigationPoints/SwiperNavigationPoints.vue';
import { useEnableStep } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'EnableStep',
    emits: [
        "show"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emits = __emit;
        const dataTestProps = {
            el: 'enable-steps__form'
        };
        const { formRef, schema, uiSchema, stepDescriptionProps, enableStepSetupProps, currentLanguageTag, googlePlayIconUrl, appleStoreIconUrl, currentStepTfa, onEnableStepMounted, submitEnableHandler, goToNextStep, changeViewSetup, onInputForm, isFastOptionEnableStepSetup, labelButtonForSecondStep, isDisableButton, isKeyboardVisible, onFocus, onBlur } = useEnableStep(emits);
        onMounted(onEnableStepMounted);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['enable-steps'])
            }, [
                (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['enable-steps__step'])
                    }, [
                        _createVNode(VStepDescription, _mergeProps({
                            class: _ctx.$style['enable-steps__step-description']
                        }, _unref(stepDescriptionProps)[0]), null, 16, [
                            "class"
                        ]),
                        _createVNode(EnableStepDownload, {
                            "language-tag": _unref(currentLanguageTag),
                            "google-play-icon-url": _unref(googlePlayIconUrl),
                            "apple-store-icon-url": _unref(appleStoreIconUrl)
                        }, null, 8, [
                            "language-tag",
                            "google-play-icon-url",
                            "apple-store-icon-url"
                        ])
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['enable-steps__step'])
                    }, [
                        _createVNode(VStepDescription, _mergeProps({
                            class: _ctx.$style['enable-steps__step-description']
                        }, _unref(stepDescriptionProps)[1]), null, 16, [
                            "class"
                        ]),
                        _createVNode(EnableStepSetup, _normalizeProps(_guardReactiveProps(_unref(enableStepSetupProps))), null, 16)
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['enable-steps__step'])
                    }, [
                        _createVNode(VStepDescription, _mergeProps({
                            class: _ctx.$style['enable-steps__step-description']
                        }, _unref(stepDescriptionProps)[2]), null, 16, [
                            "class"
                        ]),
                        _withDirectives(_createVNode(VForm, {
                            ref_key: "formRef",
                            ref: formRef,
                            class: _normalizeClass(_ctx.$style['enable-steps__form']),
                            schema: _unref(schema),
                            "ui-schema": _unref(uiSchema),
                            onSubmit: _unref(submitEnableHandler)
                        }, null, 8, [
                            "class",
                            "schema",
                            "ui-schema",
                            "onSubmit"
                        ]), [
                            [
                                _directive_data_test,
                                dataTestProps
                            ]
                        ])
                    ], 2)
                ], 64))
            ], 2)), [
                [
                    _directive_auto_id,
                    'EnableStep'
                ]
            ]);
        };
    }
});
