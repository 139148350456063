import { computed, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { Timer } from '@leon-hub/utils';
import RouteName from '@leon-hub/routing-config-names';
import { useI18n } from 'web/src/modules/i18n/composables';
import { FormControlType, FormErrorKeyword, FormFocusType } from 'web/src/components/Form/enums';
import { secretFormSchema } from 'web/src/modules/two-factor-authentication/schemas';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { DURATION_DELAY } from 'web/src/modules/two-factor-authentication/pages/TfaLoginRoutePage/constants';
import { useUserStore } from 'web/src/modules/user/store';
import useTfaDialogs from 'web/src/modules/two-factor-authentication/composables/useTfaDialogs';
import useLoginStore from 'web/src/modules/login/store/useLoginStore';
import { tfaCodePattern } from '../../../constants/tfaCodePattern';
const CODE = 'code';
export default function useTfaLoginRoutePage() {
    const { $translate } = useI18n();
    const { authG2sv } = useUserStore();
    const router = useRouter();
    const { showTfaErrorDialog } = useTfaDialogs();
    const loginStore = useLoginStore();
    const loginType = toRef(loginStore, 'loginType');
    const formRef = ref();
    const g2svInactiveInterval = toRef(useSiteConfigStore(), 'g2svInactiveInterval');
    const durationTimeout = ref(60);
    const currentDuration = ref(60);
    let loginDurationTimer = 0;
    let failCount = 0;
    const durationPercentage = computed(()=>{
        const MIN = 0;
        const MAX = 100;
        const percentage = Math.round(100 / durationTimeout.value * currentDuration.value);
        if (percentage <= MIN) return MIN;
        if (percentage >= MAX) return MAX;
        return percentage;
    });
    const uiSchema = computed(()=>({
            validatorErrorPatterns: {
                byWidget: {
                    [FormControlType.PieTimerInput]: {
                        [FormErrorKeyword.Pattern]: $translate('WEB2_INVALID_CODE').value
                    }
                }
            },
            order: [
                CODE
            ],
            submitButton: {
                label: $translate('WEB2_TFA_ENTER').value
            },
            focus: {
                type: FormFocusType.OnMount,
                field: CODE
            },
            fields: {
                code: {
                    widget: FormControlType.PieTimerInput,
                    options: {
                        timerPercentage: durationPercentage.value,
                        placeholder: $translate('JSPACC_SETT_G2SV_ENTER_CODE').value,
                        regexp: tfaCodePattern
                    }
                }
            }
        }));
    const startTimer = ()=>{
        loginDurationTimer = Timer.setInterval(()=>{
            currentDuration.value -= 1;
            if (currentDuration.value <= -DURATION_DELAY) router.replace({
                name: RouteName.LOGIN
            });
        }, 1000);
    };
    const cleanTimer = ()=>{
        if (loginDurationTimer) {
            clearInterval(loginDurationTimer);
            loginDurationTimer = 0;
        }
    };
    function submitLoginHandler(payload) {
        const { errors, formData } = payload;
        const { code } = formData;
        if (errors) {
            const errorsIterator = errors.keys();
            formRef.value?.focusByName(errorsIterator.next().value);
            return;
        }
        processLogin(code);
    }
    function processLogin(code) {
        const payload = {
            tfaCode: code || '',
            tfaFailCount: failCount,
            tfaLoginDuration: currentDuration.value,
            loginType: loginType.value
        };
        authG2sv(payload).catch((error)=>{
            onSignInErrorHandler(error);
        });
    }
    function onSignInErrorHandler(error) {
        failCount += 1;
        formRef.value?.reset();
        showTfaErrorDialog(error, $translate('WEB2_MODALTITLE_ATTENTION').value).promise.then(()=>{
            if (failCount >= 5) router.replace({
                name: RouteName.LOGIN
            });
        });
    }
    function onTfaLoginRoutePageMounted() {
        if (g2svInactiveInterval.value) {
            durationTimeout.value = g2svInactiveInterval.value;
            currentDuration.value = g2svInactiveInterval.value;
        }
        startTimer();
    }
    function onTfaLoginRoutePageBeforeUnmount() {
        cleanTimer();
    }
    return {
        schema: secretFormSchema,
        uiSchema,
        formRef,
        onTfaLoginRoutePageMounted,
        onTfaLoginRoutePageBeforeUnmount,
        submitLoginHandler
    };
}
