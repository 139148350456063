import { computed, nextTick, onUnmounted, ref, toRef, watch } from 'vue';
import { AlertIconName } from '@leon-hub/icons';
import GqlApiBatchedSubRequestError from '@leon-hub/api/src/client/graphql/errors/GqlApiBatchedSubRequestError';
import { getImageOrIcon } from 'web/src/modules/icons';
import { useTwoFactorAuthenticationStore } from 'web/src/modules/two-factor-authentication/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useI18nStore } from 'web/src/modules/i18n/store';
import { FormFieldTouchedStrategy, FormFocusType } from 'web/src/components/Form/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { secretFormSchema } from 'web/src/modules/two-factor-authentication/schemas';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import useTfaDialogs from 'web/src/modules/two-factor-authentication/composables/useTfaDialogs';
import { DialogAction } from 'web/src/modules/dialogs/enums';
import { useCopyToClipboard, useDialogs } from 'web/src/modules/dialogs/composables';
import { tfaCodeFieldOptions } from '../../../constants/tfaCodeFieldOptions';
const CODE = 'code';
export default function useEnableStep(emit) {
    const formRef = ref();
    const { $translate } = useI18n();
    const { showTfaErrorDialog, showTfaSuccessDialog } = useTfaDialogs();
    const i18nStore = useI18nStore();
    const siteConfigStore = useSiteConfigStore();
    const tfaStore = useTwoFactorAuthenticationStore();
    const customerDataStore = useCustomerDataStore();
    const { setIsShowTurnOnTfaStep } = customerDataStore;
    const { copy } = useCopyToClipboard();
    const { closeDialog } = useDialogs();
    const secret = toRef(tfaStore, 'secret');
    const g2svLink = toRef(tfaStore, 'g2svLink');
    const qrCode = toRef(tfaStore, 'qrCode');
    const reservedKeys = toRef(tfaStore, 'reservedKeys');
    const currentStepTfa = toRef(tfaStore, 'currentStepTfa');
    const currentLanguageTag = toRef(i18nStore, 'locale');
    const googlePlayIconUrl = toRef(siteConfigStore, 'googlePlayIconUrl');
    const appleStoreIconUrl = toRef(siteConfigStore, 'appleStoreIconUrl');
    let dialogId = null;
    const isFastOptionEnableStepSetup = ref(true);
    const formDataCode = ref(null);
    function changeViewSetup() {
        isFastOptionEnableStepSetup.value = !isFastOptionEnableStepSetup.value;
    }
    const labelButtonForSecondStep = computed(()=>{
        const manualLabel = $translate('WEB2_TFA_SETUP_QR_CODE_SCAN').value;
        const automaticLabel = $translate('WEB2_TFA_MANUAL_BUTTON').value;
        return isFastOptionEnableStepSetup.value ? automaticLabel : manualLabel;
    });
    const uiSchema = computed(()=>({
            validatorErrorPatterns: {
                common: {
                    pattern: $translate('WEB2_INVALID_CODE').value
                }
            },
            order: [
                CODE
            ],
            submitButton: {
                label: $translate('WEB2_TFA_ENABLE').value
            },
            focus: {
                type: FormFocusType.OnMount,
                field: CODE
            },
            fields: {
                code: {
                    title: $translate('WEB2_ENTER_CODE').value,
                    touchedStrategy: FormFieldTouchedStrategy.Change,
                    hintMessage: $translate('WEB2_TFA_COPY_CODE').value,
                    options: {
                        ...tfaCodeFieldOptions
                    }
                }
            }
        }));
    const stepDescriptionProps = computed(()=>[
            {
                step: '1',
                label: $translate('WEB2_TFA_STEP_INSTALL').value
            },
            {
                step: '2',
                label: $translate('WEB2_TFA_STEP_SETUP').value
            },
            {
                step: '3',
                label: $translate('WEB2_TFA_STEP_SYNC').value
            }
        ]);
    const customerLogin = computed(()=>{
        const { email, phoneDecodedString } = customerDataStore;
        if (email && 'N/A' !== email) return email;
        return phoneDecodedString;
    });
    const enableStepSetupProps = computed(()=>({
            secret: secret.value,
            login: customerLogin.value,
            link: g2svLink.value,
            qrCode: qrCode.value,
            email: customerDataStore.email
        }));
    function submitEnableHandler(payload) {
        const { errors, formData } = payload;
        const { code } = formData;
        if (errors) {
            const errorsIterator = errors.keys();
            formRef.value?.focusByName(errorsIterator.next().value);
            return;
        }
        processEnable(code);
    }
    async function processEnable(code) {
        try {
            await tfaStore.enableTFA({
                secret: secret.value,
                code
            });
            if (reservedKeys.value.length > 0) showSuccessDialog();
        } catch (error) {
            if (error instanceof GqlApiBatchedSubRequestError) {
                await showTfaErrorDialog(error).promise;
                formRef.value?.reset();
                await nextTick();
                formRef.value?.focus();
            }
        }
    }
    function changeStateCustomPadding(value) {}
    function showSuccessDialog() {
        changeStateCustomPadding(true);
        const { subscribe, id } = showTfaSuccessDialog({
            title: $translate('WEB2_TFA_SYNC_SUCCESS').value,
            confirmMessage: $translate('WEB2_TFA_SAVE_RESERVED_KEYS').value,
            topBarText: $translate('JS_MOBILE_SETTINGS_TFAUTH_TITLE').value,
            image: getImageOrIcon({
                alertIcon: AlertIconName.Shield
            }).image
        });
        dialogId = id;
        subscribe({
            [DialogAction.CONFIRM]: ()=>{
                copy({
                    data: reservedKeys.value.join(' '),
                    notificationText: $translate('WEB2_COPY_KEYS_TEXT').value,
                    isProfileModalTooltip: true
                });
                return false;
            },
            [DialogAction.MODAL_CLOSE]: ()=>{
                tfaStore.updateAppData();
                changeStateCustomPadding(false);
                tfaStore.resetReservedKeys();
                setIsShowTurnOnTfaStep(false);
            }
        });
    }
    function checkIfSecretUpdated(newSecretValue) {
        if (newSecretValue) tfaStore.getQRCode({
            imageSize: 150,
            secret: newSecretValue
        }).catch((error)=>showTfaErrorDialog(error));
    }
    function onEnableStepMounted() {
        tfaStore.getAuthenticatorUri().catch((error)=>{
            showTfaErrorDialog(error);
        });
    }
    function onInputForm(value) {
        formDataCode.value = value;
    }
    function goToNextStep() {
        if (currentStepTfa.value < 3) tfaStore.setCurrentStepTfaPlus();
        else if (formRef.value && formDataCode.value) submitEnableHandler(formDataCode.value);
    }
    const isDisableButton = computed(()=>{
        const { formData, errors } = formDataCode.value ?? {};
        return 3 === currentStepTfa.value && (!formData || !!errors);
    });
    watch(secret, (newSecretValue)=>{
        checkIfSecretUpdated(newSecretValue);
    });
    const isKeyboardVisible = ref(false);
    const onBlur = ()=>{
        setTimeout(()=>{
            isKeyboardVisible.value = false;
        }, 0);
    };
    const onFocus = ()=>{
        isKeyboardVisible.value = true;
    };
    onUnmounted(()=>{
        if (dialogId) closeDialog(dialogId);
    });
    return {
        formRef,
        schema: secretFormSchema,
        uiSchema,
        stepDescriptionProps,
        enableStepSetupProps,
        currentLanguageTag,
        googlePlayIconUrl,
        appleStoreIconUrl,
        currentStepTfa,
        onEnableStepMounted,
        submitEnableHandler,
        goToNextStep,
        isFastOptionEnableStepSetup,
        changeViewSetup,
        onInputForm,
        isDisableButton,
        labelButtonForSecondStep,
        isKeyboardVisible,
        onFocus,
        onBlur
    };
}
