import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { useRouter } from 'vue-router';
import { Tag } from '@leon-hub/tags';
import RouteName from '@leon-hub/routing-config';
import { IconName } from '@leon-hub/icons';
import { useTfaRegenerateRoutePage } from 'web/src/modules/two-factor-authentication/pages/TfaRegenerateRoutePage/composables';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
import TextSlotPlaceholder from 'web/src/components/TextSlotPlaceholder/components/TextSlotPlaceholder.vue';
import ActionLink from 'web/src/modules/two-factor-authentication/components/ActionLink/ActionLink.vue';
import VForm from 'web/src/components/Form/components/VForm/VForm.vue';
import { openGoogleAuthenticator } from 'web/src/modules/two-factor-authentication/utils';
import { TfaAppLink } from 'web/src/modules/two-factor-authentication/types';
import SHintBlock from 'web/src/components/HintBlock/SHintBlock/SHintBlock.vue';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'TfaRegenerateRoutePage',
    setup (__props) {
        const router = useRouter();
        const { schema, uiSchema, formRef, isCustomPaddingBottom, isDisableButton, onInputForm, submitRegenerateHandler } = useTfaRegenerateRoutePage();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['tfa-regenerate']]: true,
                    [_ctx.$style['tfa-regenerate__custom-padding-bottom']]: _unref(isCustomPaddingBottom)
                })
            }, [
                (_openBlock(), _createBlock(VHintBlock, {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['tfa-regenerate__hint'])
                }, {
                    title: _withCtx(()=>[
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('WEB2_MODALTITLE_ATTENTION')), 1)
                        ]),
                    default: _withCtx(()=>[
                            _createTextVNode(" " + _toDisplayString(_ctx.$t('WEB2_TFA_HINT')), 1)
                        ]),
                    _: 1
                }, 8, [
                    "class"
                ])),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['tfa-regenerate__content'])
                }, [
                    _createVNode(TextSlotPlaceholder, {
                        class: _normalizeClass(_ctx.$style['tfa-regenerate__label']),
                        text: _ctx.$t('WEB2_TFA_REGENERATE'),
                        tag: _unref(Tag).DIV
                    }, {
                        app: _withCtx(()=>[
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.$style['tfa-regenerate__label-link']),
                                    onClick: _cache[0] || (_cache[0] = ($event)=>_unref(openGoogleAuthenticator)(_unref(TfaAppLink).GOOGLE_AUTHENTICATOR))
                                }, " Google Authenticator ", 2)
                            ]),
                        _: 1
                    }, 8, [
                        "class",
                        "text",
                        "tag"
                    ]),
                    _createVNode(VForm, {
                        ref_key: "formRef",
                        ref: formRef,
                        class: _normalizeClass(_ctx.$style['tfa-regenerate__form']),
                        schema: _unref(schema),
                        "ui-schema": _unref(uiSchema),
                        onSubmit: _unref(submitRegenerateHandler),
                        onInput: _unref(onInputForm)
                    }, null, 8, [
                        "class",
                        "schema",
                        "ui-schema",
                        "onSubmit",
                        "onInput"
                    ]),
                    (_openBlock(), _createBlock(ActionLink, {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['tfa-regenerate__link']),
                        title: _ctx.$t('WEB2_DISABLE_TFA'),
                        onClick: _cache[1] || (_cache[1] = ($event)=>_unref(router).push({
                                name: _unref(RouteName).TWO_FACTOR_AUTHENTICATION
                            }))
                    }, null, 8, [
                        "class",
                        "title"
                    ]))
                ], 2),
                _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'TfaRegenerateRoutePage'
                ]
            ]);
        };
    }
});
