import { computed, nextTick, ref, toRef } from 'vue';
import GqlApiBatchedSubRequestError from '@leon-hub/api/src/client/graphql/errors/GqlApiBatchedSubRequestError';
import { useTwoFactorAuthenticationStore } from 'web/src/modules/two-factor-authentication/store';
import { FormFieldTouchedStrategy, FormFocusType } from 'web/src/components/Form/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { secretFormSchema } from 'web/src/modules/two-factor-authentication/schemas';
import { DialogAction } from 'web/src/modules/dialogs/enums';
import { useCopyToClipboard } from 'web/src/modules/dialogs/composables';
import useTfaDialogs from 'web/src/modules/two-factor-authentication/composables/useTfaDialogs';
import { tfaCodeFieldOptions } from 'web/src/modules/two-factor-authentication/constants/tfaCodeFieldOptions';
export default function useTfaRegenerateRoutePage() {
    const { $translate } = useI18n();
    const { copy } = useCopyToClipboard();
    const { showTfaSuccessDialog, showTfaErrorDialog } = useTfaDialogs();
    const tfaModule = useTwoFactorAuthenticationStore();
    const formRef = ref();
    const isCustomPaddingBottom = ref(false);
    const formDataCode = ref(null);
    const reservedKeys = toRef(tfaModule, 'reservedKeys');
    const uiSchema = computed(()=>({
            validatorErrorPatterns: {
                common: {
                    pattern: $translate('WEB2_INVALID_CODE').value
                }
            },
            order: [
                'code'
            ],
            submitButton: {
                label: $translate('WEB2_TFA_REGENERATE_CODES').value
            },
            focus: {
                type: FormFocusType.OnMount,
                field: 'code'
            },
            fields: {
                code: {
                    title: $translate('WEB2_ENTER_CODE').value,
                    touchedStrategy: FormFieldTouchedStrategy.Change,
                    hintMessage: $translate('WEB2_TFA_COPY_CODE').value,
                    options: {
                        ...tfaCodeFieldOptions,
                        placeholder: $translate('JSPACC_SETT_G2SV_ENTER_CODE').value
                    }
                }
            }
        }));
    const isDisableButton = computed(()=>{
        const { formData, errors } = formDataCode.value ?? {};
        return !formData?.code || !!errors;
    });
    async function submitRegenerateHandler(payload) {
        const { errors, formData } = payload;
        const { code } = formData;
        if (errors) {
            const errorsIterator = errors.keys();
            formRef.value?.focusByName(errorsIterator.next().value);
            return;
        }
        await processRegenerate(code);
    }
    async function processRegenerate(secret) {
        try {
            await tfaModule.regenerateTFA(secret);
            if (reservedKeys.value.length > 0) showSuccessDialog();
        } catch (error) {
            if (error instanceof GqlApiBatchedSubRequestError) {
                await showTfaErrorDialog(error).promise;
                formRef.value?.reset();
                formDataCode.value = null;
                await nextTick();
                formRef.value?.focus();
            }
        }
    }
    function changeStateCustomPadding(value) {}
    function showSuccessDialog() {
        changeStateCustomPadding(true);
        showTfaSuccessDialog({
            title: $translate('WEB2_TFA_RESERVED_KEYS_UPDATED').value,
            topBarText: $translate('JS_MOBILE_SETTINGS_TFAUTH_TITLE').value
        }).subscribe({
            [DialogAction.CONFIRM]: ()=>{
                copy({
                    data: reservedKeys.value.join(' '),
                    notificationText: $translate('WEB2_COPY_KEYS_TEXT').value,
                    isProfileModalTooltip: true
                });
                formRef.value?.reset();
                return false;
            },
            [DialogAction.MODAL_CLOSE]: ()=>{
                changeStateCustomPadding(false);
                formRef.value?.reset();
                formDataCode.value = null;
                tfaModule.resetReservedKeys();
            }
        });
    }
    function onInputForm(value) {
        formDataCode.value = value;
    }
    return {
        schema: secretFormSchema,
        uiSchema,
        formRef,
        isCustomPaddingBottom,
        isDisableButton,
        onInputForm,
        submitRegenerateHandler
    };
}
