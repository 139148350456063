import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock } from "vue";
const _hoisted_10 = {
    key: 1,
    class: "manual-options"
};
const _hoisted_11 = {
    class: "manual-options__description-accent"
};
const _hoisted_12 = {
    class: "manual-options__description-accent"
};
const _hoisted_13 = {
    class: "manual-options__item-accent"
};
const _hoisted_14 = {
    class: "manual-options__item-accent"
};
const _hoisted_15 = {
    class: "manual-options__item-accent"
};
import { Tag } from '@leon-hub/tags';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import VStepDescription from 'web/src/components/StepDescription/VStepDescription/VStepDescription.vue';
import TextSlotPlaceholder from 'web/src/components/TextSlotPlaceholder/components/TextSlotPlaceholder.vue';
import ActionButtonBlock from 'web/src/modules/two-factor-authentication/components/ActionButtonBlock/ActionButtonBlock.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import ActionLink from 'web/src/modules/two-factor-authentication/components/ActionLink/ActionLink.vue';
import SNumberedListItem from 'web/src/components/NumberedListItem/SNumberedListItem/SNumberedListItem.vue';
import SNumberedList from 'web/src/components/NumberedList/SNumberedList/SNumberedList.vue';
import TextInput from 'web/src/components/Input/components/TextInput/TextInput.vue';
import MultilineTextInput from 'web/src/components/Input/components/MultilineTextInput/MultilineTextInput.vue';
import { useManualOptionShared } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ManualOptionShared',
    props: {
        secret: {},
        email: {}
    },
    emits: [
        "close"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { formattedSecret, buttonProperties, stepDescriptionProps } = useManualOptionShared(props);
        const tagDiv = Tag.DIV;
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            return _openBlock(), _createElementBlock("div", _hoisted_10, [
                _createVNode(VStepDescription, _mergeProps({
                    class: "manual-options__description"
                }, _unref(stepDescriptionProps)[0]), {
                    label: _withCtx(()=>[
                            _withDirectives((_openBlock(), _createBlock(TextSlotPlaceholder, {
                                text: `1. ${_ctx.$t('WEB2_TFA_SETUP_PRESS_PLUS')}`,
                                tag: _unref(tagDiv)
                            }, {
                                field: _withCtx(()=>[
                                        _createElementVNode("span", _hoisted_11, " \"" + _toDisplayString(_ctx.$t('WEB2_PLUS')) + "\" ", 1)
                                    ]),
                                _: 1
                            }, 8, [
                                "text",
                                "tag"
                            ])), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'manual-options__item manual-options__item-1'
                                    }
                                ]
                            ])
                        ]),
                    _: 1
                }, 16),
                _createVNode(VStepDescription, _mergeProps({
                    class: "manual-options__description"
                }, _unref(stepDescriptionProps)[1]), {
                    label: _withCtx(()=>[
                            _withDirectives((_openBlock(), _createBlock(TextSlotPlaceholder, {
                                text: `2. ${_ctx.$t('WEB2_TFA_SETUP_PRESS_ENTER')}`,
                                tag: _unref(tagDiv)
                            }, {
                                field: _withCtx(()=>[
                                        _createElementVNode("span", _hoisted_12, " \"" + _toDisplayString(_ctx.$t('WEB2_MANUAL_ENTER')) + "\" ", 1)
                                    ]),
                                _: 1
                            }, 8, [
                                "text",
                                "tag"
                            ])), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'manual-options__item manual-options__item-2'
                                    }
                                ]
                            ])
                        ]),
                    _: 1
                }, 16),
                _withDirectives((_openBlock(), _createBlock(TextSlotPlaceholder, {
                    class: "manual-options__item",
                    text: `3. ${_ctx.$t('WEB2_TFA_SETUP_FIELD_EMAIL')}`,
                    tag: _unref(tagDiv)
                }, {
                    field: _withCtx(()=>[
                            _createElementVNode("span", _hoisted_13, " \"" + _toDisplayString(_ctx.$t('WEB2_TFA_SETUP_FIELD_EMAIL_LABEL')) + "\" ", 1)
                        ]),
                    _: 1
                }, 8, [
                    "text",
                    "tag"
                ])), [
                    [
                        _directive_data_test,
                        {
                            el: 'manual-options__item manual-options__item-3'
                        }
                    ]
                ]),
                _createVNode(ActionButtonBlock, {
                    class: "manual-options__action-block",
                    label: _ctx.email
                }, {
                    button: _withCtx(()=>[
                            _createVNode(VButton, _mergeProps(_unref(buttonProperties), {
                                onClick: _cache[2] || (_cache[2] = ($event)=>_ctx.$copyToClipboard({
                                        data: _ctx.email,
                                        notificationText: _ctx.$t('WEB2_COPY_EMAIL_TEXT')
                                    }))
                            }), null, 16)
                        ]),
                    _: 1
                }, 8, [
                    "label"
                ]),
                _withDirectives((_openBlock(), _createBlock(TextSlotPlaceholder, {
                    class: "manual-options__item",
                    text: `4. ${_ctx.$t('WEB2_TFA_SETUP_FIELD_SECRET')}`,
                    tag: _unref(tagDiv)
                }, {
                    field: _withCtx(()=>[
                            _createElementVNode("span", _hoisted_14, " \"" + _toDisplayString(_ctx.$t('WEB2_KEY')) + "\" ", 1)
                        ]),
                    _: 1
                }, 8, [
                    "text",
                    "tag"
                ])), [
                    [
                        _directive_data_test,
                        {
                            el: 'manual-options__item manual-options__item-4'
                        }
                    ]
                ]),
                _createVNode(ActionButtonBlock, {
                    class: "manual-options__action-block",
                    label: _unref(formattedSecret)
                }, {
                    button: _withCtx(()=>[
                            _createVNode(VButton, _mergeProps(_unref(buttonProperties), {
                                label: _ctx.$t('WEB2_COPY'),
                                onClick: _cache[3] || (_cache[3] = ($event)=>_ctx.$copyToClipboard({
                                        data: _ctx.secret,
                                        notificationText: _ctx.$t('WEB2_COPY_KEY_TEXT')
                                    }))
                            }), null, 16, [
                                "label"
                            ])
                        ]),
                    _: 1
                }, 8, [
                    "label"
                ]),
                _withDirectives((_openBlock(), _createBlock(TextSlotPlaceholder, {
                    class: "manual-options__item",
                    text: `5. ${_ctx.$t('WEB2_PRESS_FIELD')}`,
                    tag: _unref(tagDiv)
                }, {
                    field: _withCtx(()=>[
                            _createElementVNode("span", _hoisted_15, " \"" + _toDisplayString(_ctx.$t('WEB2_DONE')) + "\" ", 1)
                        ]),
                    _: 1
                }, 8, [
                    "text",
                    "tag"
                ])), [
                    [
                        _directive_data_test,
                        {
                            el: 'manual-options__item manual-options__item-5'
                        }
                    ]
                ]),
                _createVNode(ActionLink, {
                    title: _ctx.$t('WEB2_TFA_SETUP_QR_CODE_SCAN'),
                    onClick: _cache[4] || (_cache[4] = ($event)=>emit('close'))
                }, null, 8, [
                    "title"
                ])
            ]);
        };
    }
});
