import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
    class: "disable-step"
};
const _hoisted_2 = {
    class: "disable-step__description-accent"
};
import { Tag } from '@leon-hub/tags';
import RouteName from '@leon-hub/routing-config-names';
import { useDisableStep } from 'web/src/modules/two-factor-authentication/components/DisableStep/composables';
import VJumbotron from 'web/src/components/Jumbotron/VJumbotron/VJumbotron.vue';
import TextSlotPlaceholder from 'web/src/components/TextSlotPlaceholder/components/TextSlotPlaceholder.vue';
import { openGoogleAuthenticator } from 'web/src/modules/two-factor-authentication/utils';
import { TfaAppLink } from 'web/src/modules/two-factor-authentication/types';
import VForm from 'web/src/components/Form/components/VForm/VForm.vue';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'DisableStep',
    setup (__props) {
        const tagDiv = Tag.DIV;
        const tfaAppLink = TfaAppLink.GOOGLE_AUTHENTICATOR;
        const tfaRegenerateRouteName = {
            name: RouteName.TWO_FACTOR_AUTHENTICATION_REGENERATE
        };
        const { formRef, formKey, schema, uiSchema, jumbotronProperties, submitDisableHandler } = useDisableStep();
        return (_ctx, _cache)=>{
            const _component_router_link = _resolveComponent("router-link");
            _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(VJumbotron, _mergeProps(_unref(jumbotronProperties), {
                    class: "disable-step__jumbotron"
                }), null, 16),
                _createVNode(TextSlotPlaceholder, {
                    class: "disable-step__description",
                    text: _ctx.$t('WEB2_TFA_DISABLE_INFO'),
                    tag: _unref(tagDiv)
                }, {
                    field1: _withCtx(()=>[
                            _createElementVNode("span", {
                                class: "disable-step__description-link",
                                onClick: _cache[0] || (_cache[0] = ($event)=>_unref(openGoogleAuthenticator)(_unref(tfaAppLink)))
                            }, " Google Authenticator ")
                        ]),
                    field2: _withCtx(()=>[
                            _createElementVNode("span", _hoisted_2, " \"" + _toDisplayString(_ctx.$t('WEB2_DISABLE')) + "\" ", 1)
                        ]),
                    _: 1
                }, 8, [
                    "text",
                    "tag"
                ]),
                (_openBlock(), _createBlock(VForm, {
                    ref_key: "formRef",
                    ref: formRef,
                    key: _unref(formKey),
                    class: "disable-step__form",
                    schema: _unref(schema),
                    "ui-schema": _unref(uiSchema),
                    onSubmit: _unref(submitDisableHandler)
                }, null, 8, [
                    "schema",
                    "ui-schema",
                    "onSubmit"
                ])),
                (_openBlock(), _createBlock(_component_router_link, {
                    key: 1,
                    class: "disable-step__link",
                    to: tfaRegenerateRouteName
                }, {
                    default: _withCtx(()=>[
                            _createTextVNode(_toDisplayString(_ctx.$t('JSPACC_SETT_G2SV_REGENERATE_BTN')), 1)
                        ]),
                    _: 1
                }))
            ])), [
                [
                    _directive_auto_id,
                    'DisableStep'
                ]
            ]);
        };
    }
});
