import { toRef } from 'vue';
import RouteName from '@leon-hub/routing-config';
import { AbstractPrefetch } from 'web/src/modules/core/services/router/prefetch';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useUserStore } from 'web/src/modules/user/store';
let TfaPagePrefetch = class TfaPagePrefetch extends AbstractPrefetch {
    // eslint-disable-next-line class-methods-use-this
    async prefetch(router, to, from, next) {
        const customerDataStore = useCustomerDataStore();
        const g2svCustomerStatus = toRef(customerDataStore, 'g2svCustomerStatus');
        const g2svFeatureStatus = toRef(customerDataStore, 'g2svFeatureStatus');
        const { name } = to;
        await useUserStore().loadCustomerData(true);
        if (!g2svFeatureStatus.value) {
            next({
                name: RouteName.ERROR_NOT_FOUND_404
            });
            return Promise.resolve();
        }
        if (name === RouteName.TWO_FACTOR_AUTHENTICATION_REGENERATE && !g2svCustomerStatus.value) {
            next({
                name: RouteName.CUSTOMER_SETTINGS
            });
            return Promise.resolve();
        }
        next();
        return Promise.resolve();
    }
};
export { TfaPagePrefetch as default };
