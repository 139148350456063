import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = {
    class: "action-button-block"
};
const _hoisted_2 = {
    class: "action-button-block__label"
};
const _hoisted_3 = {
    class: "action-button-block__button"
};
import vDataTestUnit from 'web/src/testing/directives/vDataTestUnit';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ActionButtonBlock',
    props: {
        label: {
            default: ''
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _renderSlot(_ctx.$slots, "label", {}, ()=>[
                            _createTextVNode(_toDisplayString(_ctx.label), 1)
                        ])
                ])), [
                    [
                        _unref(vDataTestUnit),
                        {
                            el: 'action-button-block-label'
                        }
                    ]
                ]),
                _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _renderSlot(_ctx.$slots, "button")
                ])), [
                    [
                        _directive_data_test,
                        {
                            el: 'action-button-block__button'
                        }
                    ]
                ])
            ])), [
                [
                    _directive_auto_id,
                    'ActionButtonBlock'
                ]
            ]);
        };
    }
});
