import { computed } from 'vue';
import { IconName } from '@leon-hub/icons';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { StepDescriptionKind } from 'web/src/components/StepDescription/VStepDescription/enums';
export default function useManualOptionShared(props) {
    const { $translate } = useI18n();
    const formattedSecret = computed(()=>{
        const separatedText = [
            ...props.secret
        ].map((character, index)=>index % 4 === 0 ? ` ${character}` : character);
        return separatedText.join('').trim();
    });
    const buttonProperties = computed(()=>({
            label: $translate('WEB2_COPY').value,
            kind: ButtonKind.SECONDARY,
            rounded: true,
            fullWidth: true
        }));
    const stepDescriptionProps = [
        {
            iconName: IconName.PLUS,
            kind: StepDescriptionKind.BLUE
        },
        {
            iconName: IconName.PENCIL,
            kind: StepDescriptionKind.PRIMARY
        }
    ];
    return {
        formattedSecret,
        buttonProperties,
        stepDescriptionProps
    };
}
