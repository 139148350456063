import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { onMounted, ref } from 'vue';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import EnableStep from 'web/src/modules/two-factor-authentication/components/EnableStep/EnableStep.vue';
import DisableStep from 'web/src/modules/two-factor-authentication/components/DisableStep/DisableStep.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'TfaRoutePage',
    setup (__props) {
        const customerDataStore = useCustomerDataStore();
        const isCustomPaddingBottom = ref();
        onMounted(()=>{
            customerDataStore.setIsShowTurnOnTfaStep(!customerDataStore.g2svCustomerStatus);
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['tfa-page']]: true,
                    [_ctx.$style['tfa-page__custom-padding-bottom']]: isCustomPaddingBottom.value
                })
            }, [
                _unref(customerDataStore).isShowTurnOnTfaStep ? (_openBlock(), _createBlock(EnableStep, {
                    key: 0,
                    onShow: _cache[0] || (_cache[0] = ($event)=>isCustomPaddingBottom.value = $event)
                })) : (_openBlock(), _createBlock(DisableStep, {
                    key: 1
                }))
            ], 2)), [
                [
                    _directive_auto_id,
                    'TfaRoutePage'
                ]
            ]);
        };
    }
});
