import { logger as loggerInstance } from '@leon-hub/logging';
import { MobileAppOs } from 'web/src/modules/app-download/enums';
import { DownloadBadgeData } from 'web/src/modules/two-factor-authentication/types';
export default function useEnableStepDownload(props) {
    const logger = loggerInstance.createNamespace('EnableStepDownload');
    const iosProperties = {
        appType: MobileAppOs.Ios,
        ...DownloadBadgeData.Ios
    };
    const androidProperties = {
        appType: MobileAppOs.Android,
        ...DownloadBadgeData.Android
    };
    function getUrl(url) {
        const locale = props.languageTag?.split('_')[0];
        const fallBackLocale = 'en';
        try {
            // eslint-disable-next-line global-require,import/no-dynamic-require
            return require(`web/src/assets/images/apps-logos/${url}${locale}.svg`);
        } catch (error) {
            logger.error(`Missing badge image for language ${locale}`, error);
            // eslint-disable-next-line global-require,import/no-dynamic-require
            return require(`web/src/assets/images/apps-logos/${url}${fallBackLocale}.svg`);
        }
    }
    return {
        iosProperties,
        androidProperties,
        getUrl
    };
}
