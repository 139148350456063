import { computed, nextTick, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import GqlApiBatchedSubRequestError from '@leon-hub/api/src/client/graphql/errors/GqlApiBatchedSubRequestError';
import { AlertIconName, IconName, IconSize } from '@leon-hub/icons';
import { FormFocusType } from 'web/src/components/Form/enums';
import { getJumbotronProps } from 'web/src/components/Jumbotron';
import { JumbotronIconKind } from 'web/src/components/Jumbotron/VJumbotron/enums';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import useTfaDialogs from 'web/src/modules/two-factor-authentication/composables/useTfaDialogs';
import { tfaCodeFieldOptions } from 'web/src/modules/two-factor-authentication/constants/tfaCodeFieldOptions';
import { secretFormSchema } from 'web/src/modules/two-factor-authentication/schemas';
import { useTwoFactorAuthenticationStore } from 'web/src/modules/two-factor-authentication/store';
const CODE = 'code';
export default function useDisableStep() {
    useRouter();
    const { $translate } = useI18n();
    const { disableTFA, resetReservedKeys, setCurrentStepTfaInit } = useTwoFactorAuthenticationStore();
    const { showTfaErrorDialog, showTfaSuccessDialog } = useTfaDialogs();
    const customerDataStore = useCustomerDataStore();
    const g2svCustomerStatus = toRef(customerDataStore, 'g2svCustomerStatus');
    const { setIsShowTurnOnTfaStep } = customerDataStore;
    const formRef = ref();
    const formKey = ref(0);
    const uiSchema = computed(()=>({
            validatorErrorPatterns: {
                common: {
                    pattern: $translate('WEB2_INVALID_CODE').value
                }
            },
            order: [
                CODE
            ],
            submitButton: {
                label: $translate('WEB2_TFA_DISABLE').value,
                id: 'disable-step-off-modal-button'
            },
            focus: {
                type: FormFocusType.OnMount,
                field: CODE
            },
            fields: {
                code: {
                    title: $translate('WEB2_ENTER_CODE').value,
                    hintMessage: $translate('WEB2_TFA_COPY_CODE').value,
                    options: {
                        ...tfaCodeFieldOptions,
                        placeholder: $translate('JSPACC_SETT_G2SV_ENTER_CODE').value
                    }
                }
            }
        }));
    const jumbotronProperties = computed(()=>({
            ...getJumbotronProps({
                alertIcon: AlertIconName.Shield,
                iconSize: IconSize.SIZE_80,
                iconKind: JumbotronIconKind.SUCCESS,
                icon: g2svCustomerStatus.value ? IconName.SHIELD_OUTLINE : IconName.CHECK_OUTLINE
            }),
            heading: g2svCustomerStatus.value ? $translate('WEB2_TFA_ENABLED').value : $translate('JSPACC_SETT_G2SV_DISABLED').value
        }));
    function submitDisableHandler(payload) {
        const { errors, formData } = payload;
        const { code } = formData;
        if (errors) {
            const errorsIterator = errors.keys();
            formRef.value?.focusByName(errorsIterator.next().value);
            return;
        }
        processDisable(code);
    }
    async function processDisable(code) {
        try {
            await disableTFA(code);
            setCurrentStepTfaInit();
            resetReservedKeys();
            setIsShowTurnOnTfaStep(true);
        } catch (error) {
            if (error instanceof GqlApiBatchedSubRequestError) {
                await showTfaErrorDialog(error).promise;
                formKey.value += 1;
                await nextTick();
                formRef.value?.focus();
            }
        }
    }
    return {
        formRef,
        formKey,
        uiSchema,
        schema: secretFormSchema,
        jumbotronProperties,
        submitDisableHandler
    };
}
